<template>
  <ul class="pagination">
    <li class="pagination__item">
      <button
        @click="handlePageSelect(currentPage - 1)"
        type="button"
        :disabled="currentPage === 1"
      >
        Previous
      </button>
    </li>

    <li v-for="page in pages" :key="page" class="pagination__item">
      <button
        type="button"
        @click="handlePageSelect(page)"
        :disabled="page === currentPage"
      >
        {{ page }}
      </button>
    </li>

    <li class="pagination__item">
      <button
        @click="handlePageSelect(currentPage + 1)"
        type="button"
        :disabled="totalPages <= currentPage"
      >
        Next
      </button>
    </li>
  </ul>
</template>

<script>
import Vue from "vue";
const MAX_TOTAL_PAGES = 5;

export default Vue.extend({
  name: "Pagination",
  computed: {
    startPage() {
      if (this.currentPage === 1) return 1;

      if (this.currentPage === this.totalPages)
        return this.totalPages - MAX_TOTAL_PAGES;

      return this.currentPage - 1;
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage);
    },
    pages() {
      const endPage = Math.min(
        this.startPage + MAX_TOTAL_PAGES - 1,
        this.totalPages
      );
      console.log({
        endPage,
        totalCount: this.totalCount,
        perPage: this.perPage,
      });
      const length = endPage - this.startPage;
      return [...Array(length).keys()].map((i) => i + this.startPage);
    },
  },
  methods: {
    handlePageSelect(page) {
      console.log({ page });
      this.$emit("pageSelect", page);
    },
  },
  props: {
    totalCount: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.pagination {
  list-style: none;
  padding: 0;
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__item {
    list-style: none;
    padding: 0;
    margin: 0;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}
</style>
