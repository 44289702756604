export function graphQLErrorMessages(errorsFromCatch) {
  console.log({ errorsFromCatch });
  const errors = errorsFromCatch.graphQLErrors[0];
  const messages: string[] = [];
  if (!errors) {
    return ["Oops, Something went wrong!"];
  }

  if (Object.prototype.hasOwnProperty.call(errors, "functionError")) {
    const customErrors: { errors: string[] } = JSON.parse(errors.functionError);
    console.log({ customErrors });
    messages.push(...customErrors.errors);
  } else {
    console.log({ msgs: errors.message });
    messages.push(errors.message);
  }
  console.log({ messages });
  return messages;
}

export const parseQualificationsInFormInput = (data: any) => {
  const {
    qualificationId1,
    levelId1,
    qualificationId2,
    levelId2,
    qualificationId3,
    levelId3,
    ...coachWithoutQualifications
  } = data;
  const qualifications = [
    ...(qualificationId1
      ? [
          {
            qualificationId: 1,
            levelId: Number(levelId1),
          },
        ]
      : []),
    ...(qualificationId2
      ? [
          {
            qualificationId: 2,
            levelId: Number(levelId2),
          },
        ]
      : []),
    ...(qualificationId3
      ? [
          {
            qualificationId: 3,
            levelId: Number(levelId3),
          },
        ]
      : []),
  ];

  return { ...coachWithoutQualifications, qualifications };
};
