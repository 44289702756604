<template>
  <div id="app">
    <div
      class="wrapper"
      v-bind:class="{ '--wide': currentRoute.meta.isWideLayout }"
    >
      <div class="logo">
        <img src="./assets/logo.svg" class="logo__img" />
      </div>

      <ContentWrapper>
        <router-view />
      </ContentWrapper>

      <div class="footer">
        <amplify-sign-out v-if="signedIn"></amplify-sign-out>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth, Hub } from "aws-amplify";
import Vue from "vue";
import ContentWrapper from "./components/ContentWrapper.vue";
import router from "./router";

export default Vue.extend({
  name: "app",
  components: { ContentWrapper },
  data() {
    return {
      signedIn: false,
      currentRoute: router.currentRoute,
    };
  },
  beforeCreate() {
    Hub.listen("auth", (data) => {
      const { payload } = data;

      if (payload.event === "signIn") {
        this.signedIn = true;
        const groups =
          payload?.data?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups && groups.includes("MG_ADMIN")) {
          this.$router.push({ name: "CoachCreate" });
        } else if (groups && groups.includes("COACH_DATA_APPROVER")) {
          this.$router.push({ name: "CoachApprovalList" });
        } else if (groups && groups.includes("PUP_COACH")) {
          const coachId =
            payload?.data?.signInUserSession?.accessToken?.payload["sub"];
          this.$router.push({ name: "CoachForm", params: { coachId } });
        }
      }
      if (payload.event === "signOut") {
        this.signedIn = false;
        this.$router.push({ name: "SignIn" });
      }
    });
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true;
      })
      .catch(() => (this.signedIn = false));
  },
});
</script>

<style lang="scss">
@import url("https://use.typekit.net/hby3bsy.css");
@import "./scss/index.scss";
body,
html {
  line-height: 1.5;
  font-family: "aktiv-grotesk", sans-serif;
  background: #f5f5ef;
}
:root {
  * {
    font-size: 16px;
  }
}
.wrapper {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 24px;

  &.--wide {
    max-width: 1024px;
  }
}
.logo {
  &__img {
    height: 32px;
    padding: 32px 0 16px 0;
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
</style>
