































































































































































































































































































import Vue from "vue";
import gql from "graphql-tag";
import Spinner from "../components/Spinner.vue";
import Accordion from "../components/Accordion/Accordion.vue";
import AccordionItem from "../components/Accordion/AccordionItem.vue";
import { languages } from "../form/languages";
import { QUALIFICATIONS_OPTIONS } from "@/form/qualifications-options";
import Pagination from "../components/Pagination.vue";

const PAGE_SIZE = 20;

const getCoaches = gql`
  query ($where: WhereCoachesFilter, $pageNumber: Int, $pageSize: Int) {
    coaches(where: $where, pageNumber: $pageNumber, pageSize: $pageSize) {
      nodes {
        cognitoId
        firstName
        lastName
        email
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export default Vue.extend({
  name: "Resourcing",
  components: {
    Spinner,
    Accordion,
    AccordionItem,
    Pagination,
  },
  computed: {
    console: () => console,
    languageFilter() {
      const language = (this as any).filterForm.languages[0];
      if (!language || !language.language) return undefined;

      return {
        language: language.language.value,
        native:
          language.native !== undefined ? Boolean(language.native) : undefined,
        writtenFluency: language.writtenFluency
          ? language.writtenFluency.toLowerCase()
          : undefined,
        spokenFluency: language.spokenFluency
          ? language.spokenFluency.toLowerCase()
          : undefined,
      };
    },
    audienceLevelFilter() {
      const audienceLevel = (this as any).filterForm.audienceLevel?.value;
      if (!audienceLevel) return undefined;
      const audienceLevelNum = Number(audienceLevel);
      const MAX_AL = 8;
      const diffAL = MAX_AL - audienceLevelNum + 1;

      return Array.from(Array(diffAL).keys()).map(
        (item) => item + audienceLevelNum
      );
    },
    qualificationsFilter() {
      const { qualifications } = (this as any).filterForm;
      const filter = [
        ...(qualifications[0].isSelected
          ? [
              {
                qualificationId: 1,
                levelId: qualifications[0].level
                  ? Number(qualifications[0].level.value)
                  : undefined,
              },
            ]
          : []),
        ...(qualifications[1].isSelected
          ? [
              {
                qualificationId: 2,
                levelId: qualifications[1].level
                  ? Number(qualifications[1].level.value)
                  : undefined,
              },
            ]
          : []),
        ...(qualifications[2].isSelected
          ? [
              {
                qualificationId: 3,
                levelId: qualifications[2].level
                  ? Number(qualifications[2].level.value)
                  : undefined,
              },
            ]
          : []),
      ];

      if (!filter.length) return undefined;

      return filter;
    },
    qualificationsOptions: () =>
      Object.entries(QUALIFICATIONS_OPTIONS).reduce(
        (options, [key, option]) => ({
          ...options,
          [key]: {
            qualification: option.qualification,
            levels: Object.entries(option.levels).map(([value, label]) => ({
              value,
              label,
            })),
          },
        }),
        {}
      ),
    languageOptions: () =>
      languages.map((lang) => {
        return {
          label: lang.English,
          value: lang["alpha3-b"],
        };
      }),
    audienceLevelOptions: () =>
      Object.entries({
        1: "No Corporate Experience",
        2: "Students / Interns",
        3: "Individual Contributors",
        4: "High-Potential Employees",
        5: "Managers",
        6: "Directors",
        7: "Senior VP",
        8: "C-Suite",
      }).map(([value, label]) => ({ label, value })),
  },
  data() {
    return {
      filterForm: {
        qualifications: [
          { isSelected: false, level: undefined },
          { isSelected: false, level: undefined },
          { isSelected: false, level: undefined },
        ],
        industryExperience: [],
        focusArea: [],
        isApproved: true,
        isPerformaPool: true,
        firstName: "",
        languages: [{}],
        audienceLevel: undefined,
        areAllIndustryExperienceRequired: false,
        areAllfocusAreaRequired: false,
      },
      page: 1,
      pageSize: PAGE_SIZE,
      queryError: undefined,
    };
  },
  apollo: {
    coaches: {
      query: getCoaches,
      debounce: 200,
      result(result) {
        if (result.data && !result.errors) {
          (this as any).queryError = undefined;
        } else {
          (this as any).queryError = "Oops! Something went wrong :(";
        }
      },
      variables() {
        const { filterForm, page } = this as any;
        return {
          pageNumber: page,
          pageSize: PAGE_SIZE,
          where: {
            industryExperience: {
              [filterForm.areAllIndustryExperienceRequired
                ? "_contains"
                : "_contains_any"]: filterForm.industryExperience,
            },
            focusArea: {
              [filterForm.areAllfocusAreaRequired
                ? "_contains"
                : "_contains_any"]: filterForm.focusArea,
            },
            approved: { _is: filterForm.isApproved },
            performaPool: { _is: filterForm.isPerformaPool },
            firstName: filterForm.firstName
              ? { _is: filterForm.firstName }
              : undefined,
            languages: (this as any).languageFilter
              ? { _contains: [(this as any).languageFilter] }
              : undefined,
            qualifications: (this as any).qualificationsFilter
              ? { _contains: (this as any).qualificationsFilter }
              : undefined,
            audienceLevel: (this as any).audienceLevelFilter
              ? { _contains_any: (this as any).audienceLevelFilter }
              : undefined,
          },
        };
      },
    },
  },
  methods: {
    handlePageSelect(page: number) {
      (this as any).page = page;
    },
  },
});
