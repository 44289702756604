import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "@apollo/client/link/error";
import { logErrorMessages } from "@vue/apollo-util";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { Auth } from "aws-amplify";

const authLink = setContext(async (_, { headers }) => {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// NOTE it won't support batching
const httpLink = createUploadLink({
  uri: process.env.VUE_APP_COACH_PROFILE_API_URL,
});

const errorLink = onError((error) => {
  if (process.env.NODE_ENV !== "production") {
    logErrorMessages(error);
  }
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink as any).concat(httpLink as any) as any,
  cache,
});
