



































































import Vue from "vue";

export default Vue.extend({
  name: "Spinner",
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    cssVars() {
      return {
        "--width": `${this.size}px`,
        "--height": `${this.size}px`,
      };
    },
  },
});
