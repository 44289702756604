



























import Vue from "vue";
import Spinner from "../components/Spinner.vue";
import triggerCoachOnboarding from "../integrations/trigger-coach-onboarding";

export default Vue.extend({
  name: "CoachCreate",
  components: {
    Spinner,
  },
  data(): {
    submissionError: string;
    isSubmitting: boolean;
    isGreatSuccess: boolean;
    schema: any;
    formValues: any;
  } {
    return {
      submissionError: "",
      isSubmitting: false,
      isGreatSuccess: false,
      formValues: {
        onboardingSteps: ["cognito", "coachProfile"],
        platformsRequired: ["PERFORMA"],
      },
      schema: [
        {
          type: "text",
          name: "fname",
          label: "First name",
          validation: "required",
        },
        {
          type: "text",
          name: "lname",
          label: "Last name",
          validation: "required",
        },
        {
          type: "email",
          name: "email",
          label: "Email",
          validation: "required|email",
        },
        {
          // https://www.npmjs.com/package/@cone2875/vue-formulate-select
          // https://vue-select.org/
          type: "vue-select",
          name: "onboardingSteps",
          label: "Onboarding Steps",
          help: "Please choose steps to complete",
          validation: "min:1|max:5",
          multiple: true,
          validationMessages: {
            min: "Please choose at least one step",
            max: "Please choose a maximum of five steps",
          },
          options: {
            cognito: "Create Cognito Account",
            acuity: "Create Acuity Calendar/Services",
            zoom: "Create Premium Zoom Account",
            coachProfile: "Create Coach Profile Entry",
            emailCoach: "Send Onboarding Email to the Coach",
            netSuite: "Create a NetSuite vendor",
          },
        },
        {
          // https://www.npmjs.com/package/@cone2875/vue-formulate-select
          // https://vue-select.org/
          type: "vue-select",
          name: "platformsRequired",
          label: "Platforms Required",
          help: "These are used to determine Acuity Services & Coach Profile Flags",
          validation: "min:1|max:2",
          multiple: true,
          validationMessages: {
            min: "Please choose at least one step",
            max: "Please choose a maximum of five steps",
          },
          options: {
            PERFORMA: "Performa",
            LIVE: "Live",
          },
        },
      ],
    };
  },
  methods: {
    async handleSubmit(data): Promise<void> {
      this.submissionError = "";
      this.isSubmitting = true;
      console.log(process.env);

      const { ...coachInput } = data;
      try {
        await triggerCoachOnboarding(coachInput);

        this.isGreatSuccess = true;
      } catch (err: any) {
        this.submissionError = err.message
          ? err.message
          : "Unknown Failure - Contact Coachella Support";
      } finally {
        this.isSubmitting = false;
      }
    },
  },
});
