























import Vue from "vue";
import gql from "graphql-tag";
import Spinner from "../components/Spinner.vue";

const getCoaches = gql`
  query ($filter: CoachesFilter) {
    coaches(filter: $filter) {
      nodes {
        cognitoId
        firstName
        lastName
      }
    }
  }
`;

export default Vue.extend({
  name: "CoachApprovalList",
  components: {
    Spinner,
  },
  computed: {
    console: () => console,
  },
  data() {
    return {};
  },
  apollo: {
    coaches: {
      query: getCoaches,
      variables() {
        return {
          filter: { approved: false },
        };
      },
    },
  },
  methods: {},
});
