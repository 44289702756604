var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.cType,_vm._g({tag:"component",class:[
    'button typography-micro-header',
    ( _obj = {
      'button--disabled': _vm.$props.disabled,
      'button--secondary': _vm.buttonStyleComputed === 'secondary',
      'button--bg-white': _vm.buttonStyleComputed === 'bg-white',
      'button--bg-color': _vm.buttonStyleComputed === 'bg-color',
      'button--hovered': _vm.hovered
    }, _obj[("button--direction-" + (_vm.$props.iconDirection))] = _vm.$props.icon, _obj ) ].concat( _vm.colorClasses ),style:(_vm.styles),attrs:{"path":_vm.$props.path || null,"external":_vm.$props.external,"no-follow":_vm.$props.noFollow,"disabled":_vm.$props.disabled,"type":_vm.$props.type},on:{"mousemove":_vm.hover,"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},_vm.$attrs),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }