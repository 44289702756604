




















































import Vue from "vue";

export default Vue.extend({
  name: "MyFormulateImageUpload",
  data() {
    return {
      trigger: false,
    };
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      return (this.context as any).type;
    },
    attributes() {
      return (this.context as any).attributes || {};
    },
    hasValue() {
      return (this.context as any).hasValue;
    },
  },
  methods: {
    changeTrigger() {
      (this as any).trigger = true;
    },
    async setProfilePic(cropperInstance) {
      const image = cropperInstance
        .getCroppedCanvas({
          maxWidth: 1024,
          maxHeight: 1024,
          naturalWidth: 1024,
          naturalHeight: 1024,
        })
        .toDataURL("image/jpeg");

      ((this as any).context as any).model = {
        urlPreview: image,
        dataUrlFileToUpload: image,
      };
    },
  },
});
