import { Auth } from "aws-amplify";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// views
import CoachCreate from "../views/CoachCreate.vue";
import CoachForm from "../views/CoachForm.vue";
import CoachAdmin from "../views/CoachAdmin.vue";
import CoachApprovalForm from "../views/CoachApprovalForm.vue";
import CoachApprovalList from "../views/CoachApprovalList.vue";
import Resourcing from "../views/Resourcing.vue";
import SignIn from "../views/SignIn.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/review",
    name: "CoachApprovalList",
    component: CoachApprovalList,
    meta: { requiresAuth: true, requiresGroup: "COACH_DATA_APPROVER" },
  },
  {
    path: "/resourcing",
    name: "Resourcing",
    component: Resourcing,
    meta: {
      requiresAuth: true,
      requiresGroup: "COACH_DATA_APPROVER",
      isWideLayout: true,
    },
  },
  {
    path: "/review/:coachId",
    name: "CoachApprovalForm",
    component: CoachApprovalForm,
    meta: { requiresAuth: true, requiresGroup: "COACH_DATA_APPROVER" },
  },
  {
    path: "/create",
    name: "CoachCreate",
    component: CoachCreate,
    meta: { requiresAuth: true, requiresGroup: "MG_ADMIN" },
  },
  {
    path: "/edit/:coachId",
    name: "CoachForm",
    component: CoachForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/:coachId",
    name: "CoachAdmin",
    component: CoachAdmin,
    meta: { requiresAuth: true, requiresGroup: "MG_ADMIN" },
  },
  {
    path: "/sign-in/:email?/:password?",
    name: "SignIn",
    component: SignIn,
  },
  { path: "/", redirect: { name: "SignIn" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const isInGroup = (user, requiresGroup) => {
  if (!requiresGroup) return true;

  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  console.log({ groups });
  return groups.includes(requiresGroup);
};

router.beforeEach((to, from, next) => {
  const requiresAuth = to?.meta?.requiresAuth;
  const requiresGroup = to?.meta?.requiresGroup;
  if (requiresAuth) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (isInGroup(user, requiresGroup)) {
          next();
        } else {
          next({
            path: "/sign-in",
          });
        }
      })
      .catch(() => {
        next({
          path: "/sign-in",
        });
      });
  }
  next();
});

export default router;
