import { Auth } from "aws-amplify";
import axios, { AxiosRequestHeaders } from "axios";

type OnboardingSteps = {
  cognito: boolean;
  acuity: boolean;
  zoom: boolean;
  coachProfile: boolean;
  emailCoach: boolean;
};

type OnboardingStepsInput = string[];

type TriggerOnboardingInput = {
  fname: string;
  lname: string;
  email: string;
  timezone?: string;
  countryCode?: string;
  onboardingSteps: OnboardingStepsInput;
  platformsRequired: ("PERFORMA" | "LIVE")[];
};

type OnboardingData = {
  fname: string;
  lname: string;
  email: string;
  timezone: string;
  countryCode: string;
  onboardingSteps: OnboardingSteps;
  platformsRequired: ("PERFORMA" | "LIVE")[];
};

const getHeaders = async (): Promise<AxiosRequestHeaders> => {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

  return {
    Authorization: token ? `Bearer ${token}` : "",
  };
};

const mapOnboardingSteps = (input: OnboardingStepsInput): OnboardingSteps => {
  return input.reduce(
    (prev: OnboardingSteps, curr: string) => {
      return {
        ...prev,
        [curr]: true,
      };
    },
    {
      cognito: false,
      acuity: false,
      zoom: false,
      coachProfile: false,
      emailCoach: false,
    }
  );
};

const triggerCoachOnboarding = async (
  input: TriggerOnboardingInput
): Promise<OnboardingData> => {
  const headers = await getHeaders();

  const requestData: OnboardingData = {
    ...input,
    timezone: input.timezone ? input.timezone : "Europe/London", // TODO update form to use availableTimezones
    countryCode: input.countryCode ? input.countryCode : "GB", // TODO Update form to use availableCountries
    onboardingSteps: mapOnboardingSteps(input.onboardingSteps),
  };
  try {
    const response = await axios({
      url: `${process.env.VUE_APP_TRIGGER_ONBOARDING_URL}/onboarding/start`,
      method: "post",
      headers,
      data: requestData,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data as OnboardingData;
    }
    throw new Error(
      `Failed to trigger onboarding with status code ${response.status} - ${response.statusText}`
    );
  } catch (err: any) {
    throw new Error(
      err?.message
        ? err?.message
        : `Failed to trigger onboarding with unknown error`
    );
  }
};

export default triggerCoachOnboarding;
