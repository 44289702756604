import { languages } from "./languages";

export const formSchema = (isDisabled = false): any => [
  {
    type: "text",
    name: "firstName",
    label: "First name",
    validation: "required",
    disabled: isDisabled,
  },
  {
    type: "text",
    name: "lastName",
    label: "Last name",
    validation: "required",
    disabled: isDisabled,
  },
  {
    type: "select",
    name: "gender",
    label: "Gender",
    placeholder: "Select",
    options: {
      male: "Male",
      female: "Female",
      "non-binary": "Non-binary",
      other: "Other",
      "prefer not to say": "Prefer not to say",
    },
    validation: "required",
    disabled: isDisabled,
  },
  {
    type: "text",
    name: "phoneNumber",
    label: "Phone number",
    help: "Please include your country code, for example +442078931412.",
    validation: "required|matches:/\\+?[0-9 ]+/",
    disabled: isDisabled,
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    help: "This email address will be used as your Performa log in, as well as where we'll contact you with information about coaching with us.",
    validation: "required|email",
    // Once a user is created in Cognito, their email address cannot be updated. Therefore don't let users change their email.
    disabled: true,
  },
  {
    type: "email",
    name: "conferencingEmail",
    label: "Conferencing email",
    help: "You can use the same email address for conferencing. However, this email address will be used for your MindGym Zoom and Acuity account access, so it cannot be linked to another Zoom or Acuity account.",
    validation: "email",
    disabled: isDisabled,
  },
  {
    component: "label",
    children: "Address",
  },
  {
    type: "group",
    name: "address",
    validation: "min:1,length",
    repeatable: false,
    value: [{}],
    disabled: isDisabled,
    children: [
      {
        type: "text",
        name: "address1",
        label: "Address line 1",
        validation: "required",
        disabled: isDisabled,
      },
      {
        type: "text",
        name: "address2",
        label: "Address line 2",
        disabled: isDisabled,
      },
      {
        type: "text",
        name: "address3",
        label: "Address line 3",
        disabled: isDisabled,
      },
      {
        type: "text",
        name: "city",
        label: "City",
        validation: "required",
        disabled: isDisabled,
      },
      {
        type: "text",
        name: "stateProvinceCounty",
        label: "State/Province/County",
        disabled: isDisabled,
      },
      {
        type: "text",
        name: "country",
        label: "Country",
        validation: "required",
        disabled: isDisabled,
      },
      {
        type: "text",
        name: "postalCode",
        label: "Post code",
        validation: "required",
        disabled: isDisabled,
      },
    ],
  },
  {
    type: "select",
    name: "utcOffsetMinutes",
    label: "Time zone",
    placeholder: "Select",
    validation: "required|number",
    help: "Please indicate the time zone you will be available to coach. This can be different to your local business hours.",
    options: Array.from(Array(25).keys())
      .map((index) => index - 12)
      .map((utcDiffHours) => ({
        value: utcDiffHours * 60,
        label:
          "UTC " +
          (utcDiffHours < 0 ? "-" : "+") +
          Math.abs(utcDiffHours).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ":00",
      })),
    disabled: isDisabled,
  },
  {
    type: "text",
    name: "emergencyContactName",
    label: "Emergency contact name",
    validation: "required",
    disabled: isDisabled,
  },
  {
    type: "text",
    name: "emergencyContactNumber",
    label: "Emergency contact number",
    validation: "required|matches:/\\+?[0-9 ]+/",
    disabled: isDisabled,
  },
  {
    component: "h2",
    children: "Your profile on Performa",
  },
  {
    component: "p",
    children:
      "The information you provide in this section will be visible to all Performa coaching participants. We'll review it prior to publishing, and it may be edited for style and consistency.",
  },
  {
    type: "text",
    name: "shortBio",
    label: "Short biography",
    // Temporarily removed length validation to allow January 2022 cohort of coaches to be added
    //validation: "required|max:180,length",
    validation: "required",
    help: "Tell us how you'll help a coachee get where they want to go. And then what's unique about your sessions. Write in 1st person, for example I help or My expertise. Please don't mention companies you've worked for. 180 character limit with spaces.",
    disabled: isDisabled,
  },
  {
    type: "textarea",
    name: "fullBio",
    label: "Full biography",
    // Temporarily removed length validation to allow January 2022 cohort of coaches to be added
    //validation: "required|max:1000,length",
    validation: "required",
    help: "Tell us how you'll help a coachee get where they want to go. And then what's unique about your sessions. Write in 1st person, for example I help or My expertise. Please don't mention companies you've worked for. 1,000 character limit with spaces.",
    disabled: isDisabled,
  },
  {
    type: "select",
    name: "yearStartedCoaching",
    label: "Year started coaching",
    placeholder: "Select",
    validation: "required",
    options: Array.from(Array(80).keys())
      .map((index) => new Date().getFullYear() - index)
      .map((year) => ({
        value: year,
        label: year,
      })),
    disabled: isDisabled,
  },
  {
    component: "label",
    children: "Languages",
  },
  {
    type: "group",
    name: "languages",
    validation: "min:1,length",
    repeatable: true,
    "add-label": "+ Add language",
    value: [{}],
    help: "Your fluency level should be based on the ability to have behavioural science and business-orientated conversations.",
    disabled: isDisabled,
    validationMessages: {
      min: "Please enter at least one language",
    },
    children: [
      {
        // https://www.npmjs.com/package/@cone2875/vue-formulate-select
        // https://vue-select.org/
        type: "vue-select",
        name: "language",
        label: "Language",
        placeholder: "Select one",
        validation: "required",
        options: languages.map((lang) => {
          return {
            label: lang.English,
            value: lang["alpha3-b"],
          };
        }),
        disabled: isDisabled,
      },
      {
        type: "select",
        name: "spokenFluency",
        label: "Spoken fluency",
        placeholder: "Select",
        validation: "required",
        options: {
          basic: "Basic",
          intermediatte: "Intermediate",
          advanced: "Advanced",
          fluent: "Fluent",
        },
        disabled: isDisabled,
      },
      {
        type: "select",
        name: "writtenFluency",
        label: "Written fluency",
        validation: "required",
        placeholder: "Select",
        options: {
          basic: "Basic",
          intermediatte: "Intermediate",
          advanced: "Advanced",
          fluent: "Fluent",
        },
        disabled: isDisabled,
      },
      {
        type: "checkbox",
        name: "native",
        label: "Native language",
        disabled: isDisabled,
      },
    ],
  },
  {
    // https://www.npmjs.com/package/@cone2875/vue-formulate-select
    // https://vue-select.org/
    type: "vue-select",
    name: "industryExperience",
    label: "Industry experience",
    validation: "min:1|max:5",
    multiple: true,
    help: "Please choose up to five.",
    disabled: isDisabled,
    validationMessages: {
      min: "Please choose at least one industry experience",
      max: "Please choose a maximum of five industry experiences",
    },
    options: {
      "Aerospace & Defence": "Aerospace & Defence",
      "Business Services": "Business Services",
      Energy: "Energy",
      "Finance – Insurance": "Finance – Insurance",
      "Finance – Investment Banking": "Finance – Investment Banking",
      "Finance – Retail Banking": "Finance – Retail Banking",
      FMCG: "FMCG",
      "Health & Pharmaceuticals": "Health & Pharmaceuticals",
      "Hospitality & Leisure": "Hospitality & Leisure",
      Media: "Media",
      "Non-Profit": "Non-Profit",
      "Property Services": "Property Services",
      Retail: "Retail",
      Technology: "Technology",
      Telecommunications: "Telecommunications",
      Transportation: "Transportation",
      Utilities: "Utilities",
      Wholesale: "Wholesale",
      "Central Government": "Central Government",
      "Local Government": "Local Government",
      "Marketing and communication": "Marketing and communication",
    },
  },
  {
    // https://www.npmjs.com/package/@cone2875/vue-formulate-select
    // https://vue-select.org/
    type: "vue-select",
    name: "focusAreas",
    label: "Focus areas",
    help: "Please choose up to three.",
    validation: "min:1|max:3",
    multiple: true,
    validationMessages: {
      min: "Please choose at least one focus area",
      max: "Please choose a maximum of three focus areas",
    },
    options: {
      Change: "Change",
      "Communicating Effectively": "Communicating Effectively",
      "Diversity, equity and inclusion": "Diversity, equity and inclusion",
      "Managing Workplace Relationships": "Managing Workplace Relationships",
      "Performance Management": "Performance Management",
      "Presence & Influencing Others": "Presence & Influencing Others",
      "Remote Working": "Remote Working",
      "Time Management": "Time Management",
      Wellbeing: "Wellbeing",
    },
    disabled: isDisabled,
  },
  {
    type: "select",
    name: "highestAudienceLevelCoached",
    label: "Highest audience level you've coached",
    placeholder: "Select",
    options: {
      1: "No Corporate Experience",
      2: "Students / Interns",
      3: "Individual Contributors",
      4: "High-Potential Employees",
      5: "Managers",
      6: "Directors",
      7: "Senior VP",
      8: "C-Suite",
    },
    validation: "required",
    disabled: isDisabled,
  },
  {
    // https://www.npmjs.com/package/@cone2875/vue-formulate-select
    // https://vue-select.org/
    type: "vue-select",
    name: "travelAvailability",
    label: "Travel availability",
    validation: "min:1,length",
    validationMessages: {
      min: "Please choose your travel availability",
    },
    help: "Performa is currently delivered virtually. If this changed, please tell us how far you'd be able to travel.",
    multiple: true,
    options: {
      1: "None",
      2: "Local",
      3: "National",
      4: "Continental",
      5: "Worldwide",
    },
    disabled: isDisabled,
  },
  {
    component: "label",
    children: "Testimonials",
  },
  {
    type: "group",
    name: "testimonials",
    repeatable: true,
    "add-label": "+ Add testimonial",
    value: [{}],
    disabled: isDisabled,
    children: [
      {
        type: "text",
        name: "quote",
        label: "Quote",
        help: "Please provide a quote from someone you've coached. However, you must ensure you have their permission before submitting. The quote must not reference any personal information about your testimonial provider, or information about their company. 100 character limit with spaces.",
        validation: "required|max:100,length",
        disabled: isDisabled,
      },
      {
        type: "text",
        name: "participant",
        label: "Participant name",
        help: "40 character limit with spaces.",
        validation: "required|max:40,length",
        disabled: isDisabled,
      },
      {
        type: "text",
        name: "participantJobTitle",
        label: "Participant job title",
        help: "60 character limit with spaces.",
        validation: "required|max:60,length",
        disabled: isDisabled,
      },
    ],
  },
];
