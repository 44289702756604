import Vue from "vue";
import App from "./App.vue";
import VueApollo from "vue-apollo";
import { apolloClient } from "./apollo";
import AvatarCropper from "vue-avatar-cropper";
import VueFormulate from "@braid/vue-formulate";
import FormulateVSelectPlugin from "@cone2875/vue-formulate-select";
import MyFormulateImageUpload from "./components/MyFormulateImageUpload/index.vue";
import router from "./router";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import Amplify from "aws-amplify";
import amplifyConfiguration from "../amplify-config";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import ToggleButton from "vue-js-toggle-button";

Vue.component("v-select", vSelect);
Vue.use(ToggleButton);

Amplify.configure(amplifyConfiguration);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

Vue.config.productionTip = false;

Vue.use(VueApollo);
Vue.component("AvatarCropper", AvatarCropper);
Vue.component("MyFormulateImageUpload", MyFormulateImageUpload);

Vue.use(VueFormulate, {
  plugins: [FormulateVSelectPlugin],
  library: {
    profilepic: {
      classification: "custom",
      component: "MyFormulateImageUpload",
      slotProps: {
        component: ["change", "setProfilePic", "picPreview"],
      },
    },
  },
  validationNameStrategy: ["validationName", "label", "name"],
  // https://vueformulate.com/guide/validation/#globally-add-edit-validation-rule-message
  locales: {
    en: {
      required() {
        return "Please complete this field";
      },
    },
  },
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  render: (h) => h(App),
  router,
  apolloProvider,
}).$mount("#app");
