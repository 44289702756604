






























import Vue from "vue";

export default Vue.component("Button", {
  props: {
    path: {
      type: String,
      required: false,
      default: "",
    },
    external: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: String,
      validator: (style) =>
        ["primary", "secondary", "bg-white", "bg-color"].includes(style),
    },
    color: {
      // When `type` is `bg-white` or `bg-color`
      type: String,
      default: "teal",
      validator: (color) => ["teal", "blue", "purple", "red"].includes(color),
    },
    noFollow: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDirection: {
      type: String,
      default: "left",
      validator: (direction) => ["left", "right"].includes(direction),
    },
    type: {
      type: String,
      default: "button",
      validator: (type) => ["button", "submit", "reset"].includes(type),
    },
  },
  data: (): { hovered: boolean; styles: any } => ({
    hovered: false,
    styles: {
      "--x": "",
      "--y": "",
    },
  }),
  computed: {
    cType() {
      return "button";
    },
    buttonStyleComputed() {
      if (this.buttonStyle !== undefined) {
        return this.buttonStyle;
      }
      return this.secondary ? "secondary" : "primary";
    },
    colorClasses() {
      if (
        this.buttonStyleComputed === "bg-white" ||
        this.buttonStyleComputed === "bg-color"
      ) {
        const colorClasses = {
          teal: "button--teal",
          red: "button--red",
          blue: "button--blue",
          purple: "button--purple",
        };
        const cssClass = colorClasses[this.color];
        return [cssClass];
      }
      return [];
    },
  },
  methods: {
    hover(event) {
      // Calling getBoundingClientRect() on the component occasionally throws an error in Firefox
      try {
        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.styles["--x"] = `${x}px`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.styles["--y"] = `${y}px`;
        this.$emit("mousemove", event);
      } catch (e) {
        // Do nothing
      }
    },
    handleMouseEnter(event) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.hovered = true;
      this.$emit("mouseenter", event);
    },
    handleMouseLeave(event) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.hovered = false;
      this.$emit("mouseleave", event);
    },
  },
});
