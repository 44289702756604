































































































import Vue from "vue";
import { formSchema } from "../../form/formSchema";
import { QUALIFICATIONS_OPTIONS } from "../../form/qualifications-options";
import Button from "../Button/index.vue";
import Spinner from "../Spinner.vue";

export default Vue.extend({
  name: "Form",
  computed: {
    console: () => console,
  },
  props: {
    isSubmitting: Boolean,
    submissionErrors: Array,
    formInitialValues: Object,
    submitButtonLabel: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
    Spinner,
  },
  data() {
    console.log({ aa: this.formInitialValues });
    return {
      values: { ...this.formInitialValues },
      schema: formSchema(this.$props.disabled),
      qualificationsOptions: QUALIFICATIONS_OPTIONS,
    };
  },
  methods: {
    scrollToError($formEl) {
      const errorWrapperEl = $formEl.querySelector('[data-has-errors="true"]');
      const errorWrapperInput =
        errorWrapperEl && errorWrapperEl.querySelector("input,select,textarea");
      if (errorWrapperInput) {
        errorWrapperInput.focus();
        errorWrapperInput.scrollIntoView({ block: "center" });
      }
    },
    async submit(submitResult) {
      console.log({ submitResult });
      const hasErrors = await submitResult.hasValidationErrors();
      console.log({ hasErrors });
      if (hasErrors) {
        return this.scrollToError(submitResult.form.$el);
      }
      const data = await submitResult.values();
      console.log({ data });
      this.$emit("submit", { data });
    },
  },
});
