<template>
  <li class="accordion__item">
    <div
      class="accordion__trigger"
      :class="{ accordion__trigger_active: visible }"
      @click="open"
    >
      <slot name="accordion-trigger"></slot>
      <div class="accordion__trigger__arrow" />
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="accordion__content" v-show="visible">
        <ul>
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  props: {},
  inject: ["Accordion"],
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.Accordion.active.includes(this.index);
    },
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = this.Accordion.active.filter(
          (item) => item !== this.index
        );
      } else {
        this.Accordion.active.push(this.index);
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
  created() {
    this.index = this.Accordion.count++;
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  &__item {
    cursor: pointer;
    border-bottom: 1px solid #ebebeb;
    position: relative;
  }

  &__content {
    ul,
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    ul {
      padding: 16px 0;
    }
  }

  &__trigger {
    display: flex;
    justify-content: space-between;
    position: relative;

    &__arrow {
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      position: absolute;
      right: 2px;
      top: calc(50% - 8px);
    }

    &_active &__arrow {
      transform: rotate(-135deg);
    }
  }

  &-enter-active,
  &-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }

  &-enter,
  &-leave-to {
    height: 0 !important;
    opacity: 0;
  }
}
</style>
