import router from "../router";

<template>
  <div
    class="container"
    v-bind:class="{ '--wide': currentRoute.meta.isWideLayout }"
  >
    <slot></slot>
  </div>
</template>

<script>
import router from "../router";
export default {
  props: {},
  data() {
    return {
      currentRoute: router.currentRoute,
    };
  },
  provide() {
    return { Accordion: this.Accordion };
  },
};
</script>

<style lang="scss">
.container {
  padding: 56px;
  border-radius: 20px;
  background: white;

  &.--wide {
    padding: 28px;
  }
}
</style>
