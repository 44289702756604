export const QUALIFICATIONS_OPTIONS = {
  1: {
    qualification: "International Coaching Federation (ICF)",
    levels: {
      1: `ICF - Associate Certified Coach (ACC)`,
      2: `ICF - Professional Certified Coach (PCC)`,
      3: `ICF - Master Certified Coach (MCC)`,
    },
  },
  2: {
    qualification: "Association of coaching (AC)",
    levels: {
      4: `AC - Executive Coach Accreditation Scheme`,
      5: `AC - Coach Accreditation Scheme`,
    },
  },
  3: {
    qualification: "European Mentoring and Coaching Council (EMCC)",
    levels: {
      6: `EMCC - Foundation Coach`,
      7: `EMCC - Practitioner/Practiced Coach`,
      8: `EMCC - Master Coach`,
    },
  },
};
