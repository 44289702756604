









































import Vue from "vue";
import gql from "graphql-tag";
import Form from "../components/CoachDataForm/index.vue";
import {
  graphQLErrorMessages,
  parseQualificationsInFormInput,
} from "../core/utils";
import Spinner from "../components/Spinner.vue";

const getCoach = gql`
  query ($filter: CoachFilter!) {
    coach(filter: $filter) {
      cognitoId
      firstName
      lastName
      middleNames
      email
      phoneNumber
      acuityCalendarId
      dateUpdated
      updatedBy
      shortBio
      fullBio
      profilePicUrl
      utcOffsetMinutes
      emergencyContactName
      emergencyContactNumber
      yearStartedCoaching
      livePool
      performaPool
      qualificationsOther
      languages {
        language
        native
        spokenFluency
        writtenFluency
      }
      address {
        address1
        address2
        address3
        city
        stateProvinceCounty
        country
        postalCode
        dateUpdated
        updatedBy
      }
      highestAudienceLevelCoached {
        level
        description
      }
      industryExperience {
        industry
      }
      focusAreas {
        focusArea
      }
      qualifications: qualificationsNext {
        qualificationId
        levelId
      }
      testimonials {
        quote
        participantJobTitle
        participant
      }
      gender
      conferencingEmail
      travelAvailability {
        id
        travelAvailability
      }
      approved
    }
  }
`;

const addProfilePicMutation = gql`
  mutation ($file: Upload!, $coachId: String!) {
    addCoachProfilePicture(picture: $file, coachId: $coachId)
  }
`;

const updateCoachMutation = gql`
  mutation ($coachId: ID!, $input: CoachBaseInput!) {
    updateCoach(cognitoId: $coachId, input: $input) {
      cognitoId
      firstName
      lastName
      middleNames
      email
      conferencingEmail
      phoneNumber
      acuityCalendarId
      shortBio
      fullBio
      gender
      utcOffsetMinutes
      yearStartedCoaching
      livePool
      performaPool
      emergencyContactName
      emergencyContactNumber
      qualificationsOther
    }
  }
`;
const updateCoachLanguages = gql`
  mutation ($coachId: ID!, $languages: [CoachLanguageInput!]!) {
    updateCoachLanguages(cognitoId: $coachId, languages: $languages) {
      cognitoId
      languages {
        language
        spokenFluency
        writtenFluency
        native
      }
    }
  }
`;

const setCoachQualifications = gql`
  mutation ($coachId: ID!, $qualifications: [QualificationInput!]!) {
    setCoachQualifications(
      cognitoId: $coachId
      qualifications: $qualifications
    ) {
      cognitoId
      qualifications: qualificationsNext {
        level
        qualification
      }
    }
  }
`;

const updateCoachAddress = gql`
  mutation ($coachId: ID!, $address: CoachAddressInput!) {
    updateCoachAddress(cognitoId: $coachId, address: $address) {
      cognitoId
      address {
        postalCode
      }
    }
  }
`;

const setCoachFocusAreas = gql`
  mutation ($coachId: ID!, $focusAreas: [String!]!) {
    setCoachFocusAreas(cognitoId: $coachId, focusAreas: $focusAreas) {
      cognitoId
      focusAreas {
        updatedBy
        dateUpdated
        focusArea
      }
    }
  }
`;

const updateCoachTestimonials = gql`
  mutation ($coachId: ID!, $testimonials: [CoachTestimonialUpdateInput!]!) {
    updateCoachTestimonials(cognitoId: $coachId, testimonials: $testimonials) {
      cognitoId
      testimonials {
        id
        quote
        participantJobTitle
        participant
      }
    }
  }
`;

const setCoachTravelAvailability = gql`
  mutation ($coachId: ID!, $travelAvailability: [Int!]!) {
    setCoachTravelAvailability(
      cognitoId: $coachId
      travelAvailability: $travelAvailability
    ) {
      firstName
      travelAvailability {
        travelAvailability
        updatedBy
        dateUpdated
      }
    }
  }
`;

export const setCoachHighestAudienceLevel = gql`
  mutation ($coachId: ID!, $level: Int!) {
    setCoachHighestAudienceLevel(cognitoId: $coachId, level: $level) {
      cognitoId
      highestAudienceLevelCoached {
        level
        description
      }
    }
  }
`;

const setCoachIndustryExperience = gql`
  mutation ($coachId: ID!, $industryExperience: [String!]!) {
    setCoachIndustryExperience(
      cognitoId: $coachId
      industryExperience: $industryExperience
    ) {
      cognitoId
      industryExperience {
        industry
      }
    }
  }
`;

export default Vue.extend({
  name: "CoachForm",
  components: {
    Form,
    Spinner,
  },
  computed: {
    console: () => console,
  },
  data(): {
    submissionErrors: string[];
    isSubmitting: boolean;
    formInitialValues: any;
    id: string;
    isGreatSuccess: boolean;
  } {
    return {
      submissionErrors: [],
      isSubmitting: false,
      formInitialValues: {},
      id: this.$route.params.coachId,
      isGreatSuccess: false,
    };
  },
  apollo: {
    coach: {
      query: getCoach,
      variables() {
        return {
          filter: { cognitoId: (this as any).id as string },
        };
      },
    },
  },
  methods: {
    // NOTE not the way to do it
    // TODO it should happen on data load
    parseCoachInput(coach: any) {
      const parsecoach = {
        ...coach,
        address: [coach.address],
        industryExperience: coach.industryExperience.map(
          ({ industry }) => industry
        ),
        focusAreas: coach.focusAreas.map(({ focusArea }) => focusArea),
        travelAvailability: coach.travelAvailability.map(({ id }) =>
          id.toString()
        ),
        highestAudienceLevelCoached: coach?.highestAudienceLevelCoached?.level,
        qualificationId1: coach.qualifications.find(
          ({ qualificationId }) => qualificationId === "1"
        ),
        levelId1: coach.qualifications.find(
          ({ qualificationId }) => qualificationId === "1"
        )?.levelId,
        qualificationId2: coach.qualifications.find(
          ({ qualificationId }) => qualificationId === "2"
        ),
        levelId2: coach.qualifications.find(
          ({ qualificationId }) => qualificationId === "2"
        )?.levelId,
        qualificationId3: coach.qualifications.find(
          ({ qualificationId }) => qualificationId === "3"
        ),
        levelId3: coach.qualifications.find(
          ({ qualificationId }) => qualificationId === "3"
        )?.levelId,
        profilePic: {
          urlPreview: coach.profilePicUrl,
        },
      };

      return parsecoach;
    },
    async handleSubmit({ data }) {
      // WTF https://stackoverflow.com/questions/56002310/property-xxx-does-not-exist-on-type-combinedvueinstancevue-read
      (this as any).submissionErrors = [];
      (this as any).isSubmitting = true;

      try {
        if (data.profilePic) {
          await (this as any).uploadProfilePic(data.profilePic, data.cognitoId);
        }

        await (this as any).updateCoach(data);
        (this as any).isGreatSuccess = true;
      } catch (err) {
        (this as any).submissionErrors = graphQLErrorMessages(err);
      } finally {
        (this as any).isSubmitting = false;
      }
    },
    async updateCoach(data: any) {
      const coachWithParsedQualifications =
        parseQualificationsInFormInput(data);

      await this.$apollo.mutate({
        mutation: setCoachQualifications,
        variables: {
          qualifications: coachWithParsedQualifications.qualifications,
          coachId: data.cognitoId,
        },
      });
      await this.$apollo.mutate({
        mutation: setCoachFocusAreas,
        variables: {
          focusAreas: data.focusAreas,
          coachId: data.cognitoId,
        },
      });
      await this.$apollo.mutate({
        mutation: updateCoachAddress,
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          address: (({ dateUpdated, __typename, updatedBy, ...address }) =>
            address)(data.address[0]),
          coachId: data.cognitoId,
        },
      });
      await this.$apollo.mutate({
        mutation: updateCoachTestimonials,
        variables: {
          testimonials: data.testimonials.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({ dateUpdated, __typename, updatedBy, ...testimonial }) =>
              testimonial
          ),
          coachId: data.cognitoId,
        },
      });
      await this.$apollo.mutate({
        mutation: updateCoachLanguages,
        variables: {
          languages: data.languages.map(
            ({ native, language, writtenFluency, spokenFluency }) => ({
              writtenFluency,
              spokenFluency,
              language,
              native: !!native,
            })
          ),
          coachId: data.cognitoId,
        },
      });

      await this.$apollo.mutate({
        mutation: setCoachTravelAvailability,
        variables: {
          travelAvailability: data.travelAvailability.map((id) => Number(id)),
          coachId: data.cognitoId,
        },
      });

      await this.$apollo.mutate({
        mutation: setCoachHighestAudienceLevel,
        variables: {
          level: Number(data.highestAudienceLevelCoached),
          coachId: data.cognitoId,
        },
      });

      await this.$apollo.mutate({
        mutation: setCoachIndustryExperience,
        variables: {
          industryExperience: data.industryExperience,
          coachId: data.cognitoId,
        },
      });

      await this.$apollo.mutate({
        mutation: updateCoachMutation,
        variables: {
          input: {
            firstName: data.firstName,
            lastName: data.lastName,
            middleNames: data.middleNames,
            email: data.email,
            conferencingEmail: data.conferencingEmail,
            phoneNumber: data.phoneNumber,
            acuityCalendarId: data.acuityCalendarId,
            shortBio: data.shortBio,
            fullBio: data.fullBio,
            gender: data.gender,
            utcOffsetMinutes: Number(data.utcOffsetMinutes),
            yearStartedCoaching: Number(data.yearStartedCoaching),
            emergencyContactName: data.emergencyContactName,
            emergencyContactNumber: data.emergencyContactNumber,
            qualificationsOther: data.qualificationsOther,
          },
          coachId: data.cognitoId,
        },
      });
    },
    async uploadProfilePic(profilePic: any, coachId: string): Promise<void> {
      if (!profilePic?.dataUrlFileToUpload) return;

      const blob = await fetch(profilePic.dataUrlFileToUpload).then((res) =>
        res.blob()
      );

      let file = new File([blob], "avatar.jpg", {
        lastModified: Date.now(),
        type: "image/jpeg",
      });

      // File upload does not work locally using serverless offline
      await this.$apollo.mutate({
        mutation: addProfilePicMutation,
        variables: {
          file: file,
          coachId,
        },
      });
    },
  },
});
