


























import Vue from "vue";
export default Vue.extend({
  name: "SignIn",
  data() {
    const inputEmail = this.$route.params.email;
    const inputPassword = this.$route.params.password;
    return {
      signInFields: [
        {
          type: "email",
          label: "",
          placeholder: "Your email address",
          inputProps: { required: true },
          value: inputEmail,
        },
        {
          type: "password",
          label: "",
          placeholder: "Your password",
          inputProps: { required: true },
          value: inputPassword,
        },
      ],
      forgotPasswordFields: [
        {
          type: "email",
          label: "",
          placeholder: "Your email address",
          inputProps: { required: true },
        },
      ],
    };
  },
});
