


















import Vue from "vue";
import gql from "graphql-tag";
import Spinner from "../components/Spinner.vue";

const getCoach = gql`
  query ($filter: CoachFilter!) {
    coach(filter: $filter) {
      cognitoId
      firstName
      lastName
      netsuiteId
      acuityCalendarId
      livePool
      performaPool
      approved
      pools {
        id
      }
    }
  }
`;

export default Vue.extend({
  name: "CoachAdmin",
  components: {
    Spinner,
  },
  data(): {
    submissionError: string;
    isSubmitting: boolean;
    isGreatSuccess: boolean;
    schema: any;
    coachId: string;
    values: any;
  } {
    return {
      submissionError: "",
      isSubmitting: false,
      isGreatSuccess: false,
      coachId: this.$route.params.coachId,
      values: {},
      schema: [
        {
          type: "text",
          name: "firstName",
          label: "First name",
          disabled: true,
        },
        {
          type: "text",
          name: "lastName",
          label: "Last name",
          disabled: true,
        },
        {
          type: "text",
          name: "netsuiteId",
          label: "Netsuite ID",
        },
        {
          type: "text",
          name: "acuityCalendarId",
          label: "Acuity calendar ID",
        },
        {
          type: "checkbox",
          name: "approved",
          label: "Is approved?",
        },
        {
          type: "checkbox",
          name: "performaPool",
          label: "Performa platform",
        },
        {
          type: "checkbox",
          name: "livePool",
          label: "Live platform",
        },
        {
          // https://www.npmjs.com/package/@cone2875/vue-formulate-select
          // https://vue-select.org/
          type: "vue-select",
          name: "pools",
          label: "Pools",
          multiple: true,
          options: {
            1: "Performa",
            2: "Live",
            3: "CHRO",
          },
        },
      ],
    };
  },
  async created() {
    const result = await this.$apollo.query({
      query: getCoach,
      variables: {
        filter: { cognitoId: this.coachId as string },
      },
    });
    this.values = this.parseCoachInput(result.data.coach);
  },
  methods: {
    parseCoachInput(coach: any) {
      const parsedCoach = {
        ...coach,
        pools: coach.pools.map(({ id }) => id.toString()),
      };
      return parsedCoach;
    },
    async handleSubmit(data): Promise<void> {
      this.submissionError = "";
      this.isSubmitting = true;

      try {
        await this.updateCoach(data);
        this.isGreatSuccess = true;
      } catch (err: any) {
        this.submissionError = err.message
          ? err.message
          : "Unknown Failure - Contact Coachella Support";
      } finally {
        this.isSubmitting = false;
      }
    },
    async updateCoach(data: any) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($coachId: ID!, $input: CoachBaseInput!) {
            updateCoach(cognitoId: $coachId, input: $input) {
              cognitoId
            }
          }
        `,
        variables: {
          input: {
            netsuiteId: data.netsuiteId,
            acuityCalendarId: data.acuityCalendarId,
            approved: data.approved,
            performaPool: data.performaPool,
            livePool: data.livePool,
          },
          coachId: data.cognitoId,
        },
      });

      // Update pools
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($coachId: ID!, $poolIds: [Int!]!) {
            setCoachPools(cognitoId: $coachId, poolIds: $poolIds) {
              cognitoId
            }
          }
        `,
        variables: {
          coachId: data.cognitoId,
          poolIds: data.pools.map((poolId) => Number(poolId)),
        },
      });
    },
  },
});
